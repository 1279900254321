import React, { useState, useEffect } from 'react';
import About from '../../about/About';
import Contact from '../../contact/Contact';
import Mission from '../../mission/Mission';
import Stepper from '../../stepper/Stepper';
import Features from '../../features/Features';
import Marketing from '../../marketing/Marketing';
import TopFooter from '../../top-footer/TopFooter';
import Testimonial from '../../testimonial/Testimonial';
import BottomFooter from '../../bottom-footer/BottomFooter';
import DialogTrigger from '../dialog-trigger/DialogTrigger';
import PrivacyPolicy from '../../privacy-policy/PrivacyPolicy';
import FloatingActionButton from '../floating-action-btn/FloatingActionBtn';



const SinglePager = ({ handleOpenModal,toggleTheme,theme,activeSection,handleNavClick }) => {

    // const [theme, setTheme] = useState("light");
    // // Initialize activeSection as 'home' to make "Home" active on load
    // const [activeSection, setActiveSection] = useState("home");
    // // Track if the user clicked a sitemap (Track manual selection)
    // const [manualOverride, setManualOverride] = useState(false);

    // // Load theme from localStorage
    // useEffect(() => {
    //     const savedTheme = localStorage.getItem("theme") || "light";
    //     setTheme(savedTheme);
    //     document.body.className = savedTheme;
    // }, []);

    // // Toggle Theme
    // const toggleTheme = () => {
    //     const newTheme = theme === "light" ? "dark" : "light";
    //     setTheme(newTheme);
    //     document.body.className = newTheme;
    //     localStorage.setItem("theme", newTheme);
    // };

    // // Navigation Tracking
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (manualOverride) return; // Skip scroll updates during manual override

    //         const sections = document.querySelectorAll('section');
    //         let currentSection = 'home'; // Default section if none are active

    //         const scrollBottomThreshold = 50; // Define how close to the bottom is considered "bottom"

    //         // Check if near the bottom of the page
    //         if (
    //             window.innerHeight + window.scrollY >=
    //             document.documentElement.scrollHeight - scrollBottomThreshold
    //         ) {
    //             currentSection = 'contact-us'; // Set "Contact" as active when at the bottom
    //         } else {
    //             // Regular section logic
    //             sections.forEach((section) => {
    //                 const rect = section.getBoundingClientRect();
    //                 if (
    //                     rect.top <= window.innerHeight / 2 &&
    //                     rect.bottom >= window.innerHeight / 2
    //                 ) {
    //                     currentSection = section.id;
    //                 }
    //             });
    //         }

    //         setActiveSection(currentSection);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [manualOverride]);

    // // Ensure the active section updates on page load or direct navigation
    // useEffect(() => {
    //     const currentHash = window.location.hash.replace('#', '') || 'home'; // Default to 'home' if no hash
    //     const targetElement = document.getElementById(currentHash);
    //     if (targetElement) {
    //         setActiveSection(currentHash); // Manually set the active section
    //         targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //     }
    // }, []);

    // const handleNavClick = (id) => {
    //     setActiveSection(id);
    //     setManualOverride(true);

    //     // Scroll smoothly to the section
    //     document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start' });

    //     // Re-enable scroll updates after 1 second
    //     setTimeout(() => setManualOverride(false), 1000);
    // };


    return (
        <div className='singlepager-wrapper'>

            {/* Contains both the StickyNavbar & Hero component */}
            <DialogTrigger toggleTheme={toggleTheme} theme={theme} activeSection={activeSection} handleNavClick={handleNavClick} />

            <About />
            <Features />
            <Marketing />
            <Mission />
            <PrivacyPolicy />
            <Stepper />
            <Testimonial />
            <Contact />
            <TopFooter />
            <BottomFooter />

            {/* Contains both the BackToTop & ChatAssistant component */}
            <FloatingActionButton />

        </div>
    );
}

export default SinglePager;