import React, { useEffect, useRef, useState } from 'react';
import './Hero.css';
import 'animate.css';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FileDownload from '@mui/icons-material/FileDownload';
import HeroVideoCover from '../../assets/video/Walkthrough-English.mp4';
import HeroVideoCover2 from '../../assets/video/Walkthrough-French.mp4';
import Typewriter from 'typewriter-effect';

import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

function Hero({ handleOpenModal }) {
    // Refs for each element to be animated
    const heroImageRef = useRef(null);
    const heroVideoRef = useRef(null);
    const typewriterRef = useRef(null);
    const leadTextRef = useRef(null);
    const joinNowBtnRef = useRef(null);
    const scrollMoreRef = useRef(null);

    const { t } = useTranslation();

    //const [lang, setLangListener] = useState('');

    const handleVideoClick = () => {
        if (heroVideoRef.current) {
            if (heroVideoRef.current.paused) {
                heroVideoRef.current.play();
            } else {
                heroVideoRef.current.pause();
            }
        }
    };

    useEffect(() => {
        const options = {
            root: null, // Uses the viewport as the root
            threshold: 0.2, // 20% visibility required to trigger
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Add specific animation classes for each element when visible
                    if (entry.target === heroImageRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    } else if (entry.target === heroVideoRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInTopRight', 'animate__delay-2s');
                    } else if (entry.target === typewriterRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');
                    } else if (entry.target === leadTextRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft');
                    } else if (entry.target === joinNowBtnRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInRight');
                    } else if (entry.target === scrollMoreRef.current) {
                        entry.target.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-2s');
                    }
                }
            });
        }, options);

        // Observe each element
        if (heroImageRef.current) observer.observe(heroImageRef.current);
        if (heroVideoRef.current) observer.observe(heroVideoRef.current);
        if (typewriterRef.current) observer.observe(typewriterRef.current);
        if (leadTextRef.current) observer.observe(leadTextRef.current);
        if (joinNowBtnRef.current) observer.observe(joinNowBtnRef.current);
        if (scrollMoreRef.current) observer.observe(scrollMoreRef.current);

        // Cleanup observer when the component unmounts
        return () => {
            if (heroImageRef.current) observer.unobserve(heroImageRef.current);
            if (heroVideoRef.current) observer.unobserve(heroVideoRef.current);
            if (typewriterRef.current) observer.unobserve(typewriterRef.current);
            if (leadTextRef.current) observer.unobserve(leadTextRef.current);
            if (joinNowBtnRef.current) observer.unobserve(joinNowBtnRef.current);
            if (scrollMoreRef.current) observer.unobserve(scrollMoreRef.current);
        };
    }, []);

    return (
        <section id="home" className='hero-wrapper section'>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-1">
                    {/* Hero image/video container */}
                    <div ref={heroImageRef} className="col-12 col-md-10 col-lg-6 hero-image-container">
                        <video
                            ref={heroVideoRef}
                            onClick={handleVideoClick}
                            loop
                            autoPlay
                            className="hero-video-cover"
                        >
                            {
                                t('op.hero.zoneZapp-walkthrough') === 'English' ? (
                                    <source src={HeroVideoCover} type="video/mp4" />
                                ) : (
                                    <source src={HeroVideoCover2} type="video/mp4" />
                                )
                            }
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    {/* Hero text and call-to-action buttons */}
                    <div className="col-lg-6 text-center text-lg-start">
                        <h1 ref={typewriterRef} className="display-5 fw-bold lh-1 mb-3">
                            <Typewriter
                                options={{
                                    strings: [
                                        DOMPurify.sanitize(t('op.hero.header1')),
                                        DOMPurify.sanitize(t('op.hero.header2')),
                                        DOMPurify.sanitize(t('op.hero.header3')),
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 75,
                                    deleteSpeed: 30,
                                    wrapperClassName: 'typewriter-text',
                                    cursorClassName: 'typewriter-cursor',
                                    html: true,
                                }}
                            />
                        </h1>
                        <p ref={leadTextRef} className="lead">
                            {t('op.hero.sub-text')}
                        </p>
                        <div ref={joinNowBtnRef} className="d-grid gap-2 d-md-flex justify-content-center justify-content-md-start">
                            <a
                                onClick={() => handleOpenModal()}
                                href="#"
                                className="btn btn-dark btn-lg px-3 py-2 mb-3">
                                {t('op.hero.action-button')} <FileDownload />
                            </a>
                        </div>
                        <div ref={scrollMoreRef} className="btn-link-container gap-2 d-md-flex align-items-center justify-content-center justify-content-md-start mt-3">
                            <IconButton
                                href="#about"
                                className="expand-icon-btn"
                                aria-label="Scroll for more"
                                style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '12px' }}
                            >
                                <ExpandMore className="expandMore-icon" />
                            </IconButton>
                            <span className="fs-sm"> {t('op.hero.action-link')} </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;