import React, { useState, useRef, useEffect } from 'react';
import './Contact.css';
import { Call, Email, Public } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import config from '../../config';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Refs for elements to animate
  const contactSectionRef = useRef(null);
  const headingRef = useRef(null);
  const subheadingRef = useRef(null);
  const emailRef = useRef(null);
  const websiteRef = useRef(null);
  const buttonRef = useRef(null);

  // Vaildation logic
  const validateName = (name) => {
    if (!name) return `${t('op.contact.errorhandling.formVaildation.validateName.requiredVaildation')}`;
    if (name.length < 2) return `${t('op.contact.errorhandling.formVaildation.validateName.lengthValidation')}`;
    if (!/^[A-Za-z\s]+$/.test(name)) return `${t('op.contact.errorhandling.formVaildation.validateName.regexValidation')}`;
    return '';
  };

  const validatePhone = (phone) => {
    if (!phone) return `${t('op.contact.errorhandling.formVaildation.validatePhone.requiredVaildation')}`;
    if (!/^\+?[0-9]{7,15}$/.test(phone)) return `${t('op.contact.errorhandling.formVaildation.validatePhone.regexValidation')}`;
    return '';
  };

  const validateEmail = (email) => {
    if (!email) return `${t('op.contact.errorhandling.formVaildation.validateEmail.requiredVaildation')}`;
    if (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,6}$/.test(email)) return `${t('op.contact.errorhandling.formVaildation.validateEmail.regexValidation')}`;
    return '';
  };

  const validateSubject = (subject) => {
    if (!subject) return `${t('op.contact.errorhandling.formVaildation.validateSubject.requiredVaildation')}`;
    if (subject.length < 3) return `${t('op.contact.errorhandling.formVaildation.validateSubject.regexValidation')}`;
    return '';
  };

  const validateMessage = (message) => {
    if (!message) return `${t('op.contact.errorhandling.formVaildation.validateMessage.requiredVaildation')}`;
    if (message.length < 10) return `${t('op.contact.errorhandling.formVaildation.validateMessage.lengthValidation')}`;
    if (message.length > 1000) return `${t('op.contact.errorhandling.formVaildation.validateMessage.regexValidation')}`;
    return '';
  };

  useEffect(() => {
    const options = {
      root: null,
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target.id === 'contact-us') {
          // Apply animations when elements come into view
          if (headingRef.current) {
            headingRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-2s');
          }
          if (subheadingRef.current) {
            subheadingRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-3s');
          }
          if (emailRef.current) {
            emailRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-4s');
          }
          if (websiteRef.current) {
            websiteRef.current.classList.add('animate__animated', 'animate__fadeInLeft', 'animate__delay-5s');
          }
          if (buttonRef.current) {
            buttonRef.current.classList.add('animate__animated', 'animate__fadeInRight', 'animate__delay-5s');
          }
        }
      });
    }, options);

    if (contactSectionRef.current) {
      observer.observe(contactSectionRef.current);
    }

    return () => {
      if (contactSectionRef.current) observer.unobserve(contactSectionRef.current);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
    setFormErrors({ ...formErrors, [name]: '' }); // Clear the error as user types
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const errors = {
      name: validateName(formData.name),
      phone: validatePhone(formData.phone),
      email: validateEmail(formData.email),
      subject: validateSubject(formData.subject),
      message: validateMessage(formData.message),
    };

    setFormErrors(errors);

    // Check if there are any errors
    if (Object.values(errors).some((error) => error)) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${config.CONTACT_FORM_ENDPOINT}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        setResponseMessage(`${t('op.contact.handleSubmit.responseMessage')}`);
        setErrorMessage('');
        setFormData({ name: '', phone: '', email: '', subject: '', message: '' });
      } else {
        setErrorMessage(result.error || `${t('op.contact.handleSubmit.errorMessageOnElse')}`);
        setResponseMessage('');
      }
    } catch (error) {
      setErrorMessage(`${t('op.contact.handleSubmit.errorMessageOnCatch')}`);
      setResponseMessage('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contact-us" ref={contactSectionRef} className="contact-wrapper section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6 ref={headingRef} className="text-start">{t('op.contact.header')}</h6>
                <h2 ref={subheadingRef} className="text-start">{t('op.contact.sub-text')}</h2>
              </div>
              <ul className="contact-info">
                <li>
                  <div ref={emailRef} className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Email className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="mailto:customerservice@zonezapp.com" className="mail-link">
                        customerservice@zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div ref={websiteRef} className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Public className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="https://online.zonezapp.com/" className="company-link">
                        online.zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-xs-12">
            <div className="contact-form">
              <form id="contact" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      name="name"
                      type="text"
                      placeholder={t('op.contact.placeholders.name')}
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                    {formErrors.name && <span className="error-text" style={{ color: '#721c24' }} >{formErrors.name}</span>}
                  </div>

                  <div className="col-md-6">
                    <input
                      name="phone"
                      type="text"
                      placeholder={t('op.contact.placeholders.phone')}
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {formErrors.phone && <span className="error-text" style={{ color: '#721c24' }} >{formErrors.phone}</span>}
                  </div>

                  <div className="col-md-6">
                    <input
                      name="email"
                      type="email"
                      placeholder={t('op.contact.placeholders.email')}
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {formErrors.email && <span className="error-text" style={{ color: '#721c24' }} >{formErrors.email}</span>}
                  </div>

                  <div className="col-md-6">
                    <input
                      name="subject"
                      type="text"
                      placeholder={t('op.contact.placeholders.subject')}
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    {formErrors.subject && <span className="error-text" style={{ color: '#721c24' }} >{formErrors.subject}</span>}
                  </div>

                  <div className="col-lg-12">
                    <textarea
                      name="message"
                      rows="6"
                      placeholder={t('op.contact.placeholders.message')}
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                    {formErrors.message && <span className="error-text" style={{ color: '#721c24' }} >{formErrors.message}</span>}
                  </div>

                  <div className="col-lg-12">
                    <button ref={buttonRef} type="submit" className="main-button-icon" disabled={loading}>
                      {loading ? <div className="loader"></div> : t('op.contact.action-button')}
                    </button>
                  </div>
                </div>
              </form>
              {responseMessage && <div className="alert alert-success">{responseMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;