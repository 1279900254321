import React from 'react';
// import './styles/UsagePolicies.css';
import './styles/UsagePolicies-X.css';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';


const UsagePolicies = ({ toggleTheme }) => {
    const { t } = useTranslation();

    // const renderList = (listKey) => {
    //     const list = t(listKey, { returnObjects: true });
    //     if (!Array.isArray(list)) return null;

    //     return (
    //         <ul>
    //             {list.map((item, index) => (
    //                 <li key={index}>{item}</li>
    //             ))}
    //         </ul>
    //     );
    // };

    // const renderSections = () => {
    //     const sections = t('usage-policies.sections', { returnObjects: true });
    //     if (!Array.isArray(sections)) return null;

    //     return sections.map((section, index) => (
    //         <section className="content-section" key={index}>
    //             <h2 className="underline-heading">{section.title}</h2>
    //             <p>{section.content}</p>
    //             {section.list && renderList(`usage-policies.sections.${index}.list`)}
    //             {section.note && <p className="note">{section.note}</p>}
    //         </section>
    //     ));
    // };

    return (
        // <section className="usage-policies-wrapper">
        //     <StickyNavbarOnePager toggleTheme={toggleTheme} />

        //     <div className="header">
        //         <Typography variant="h4" gutterBottom>
        //             <Trans i18nKey="usage-policies.header.title">
        //                 <h1>ZoneZapp Usage Policies<span className="orange-hue">.</span></h1>
        //             </Trans>
        //         </Typography>
        //     </div>



        //     <div className="container">
        //         {renderSections()}
        //     </div>


        // </section>

        <div className="policy-container">

            <StickyNavbarOnePager toggleTheme={toggleTheme} />

            <header className="policy-header">
                <h1>{t("usage-policies.header.title")}</h1>
                <h2>{t("usage-policies.header.subtitle")}</h2>
            </header>

            <section className="policy-content">
                <p>{t("usage-policies.content.introduction")}</p>

                <h3>{t("usage-policies.content.whyWeCollect.title")}</h3>
                <ul>
                    {(t("usage-policies.content.whyWeCollect.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <h3>{t("usage-policies.content.whenWeCollect.title")}</h3>
                <ul>
                    {(t("usage-policies.content.whenWeCollect.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <h3>{t("usage-policies.content.howWeProtect.title")}</h3>
                <p>{t("usage-policies.content.howWeProtect.details")}</p>

                <h3>{t("usage-policies.content.sharingDataWithThirdParties.title")}</h3>
                <p>{t("usage-policies.content.sharingDataWithThirdParties.description")}</p>
                <ul>
                    {(t("usage-policies.content.sharingDataWithThirdParties.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <h3>{t("usage-policies.content.userDataCollection.title")}</h3>
                <p>{t("usage-policies.content.userDataCollection.description")}</p>
                <ul>
                    {(t("usage-policies.content.userDataCollection.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>
                <p>{t("usage-policies.content.userDataCollection.details")}</p>

                <h3>{t("usage-policies.content.dataProtection.title")}</h3>
                <p>{t("usage-policies.content.dataProtection.details")}</p>

                <h3>{t("usage-policies.content.userControl.title")}</h3>
                <p>{t("usage-policies.content.userControl.description")}</p>
                <ul>
                    {(t("usage-policies.content.userControl.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <h3>{t("usage-policies.content.insightsAndRecommendations.title")}</h3>
                <p>{t("usage-policies.content.insightsAndRecommendations.description")}</p>
                <ul>
                    {(t("usage-policies.content.insightsAndRecommendations.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>
                <p>{t("usage-policies.content.insightsAndRecommendations.details")}</p>

                <h3>{t("usage-policies.content.complianceWithRegulations.title")}</h3>
                <p>{t("usage-policies.content.complianceWithRegulations.details")}</p>

                <h3>{t("usage-policies.content.yourControl.title")}</h3>
                <p>{t("usage-policies.content.yourControl.details")}</p>

                <h3>{t("usage-policies.content.agreement.title")}</h3>
                <p>
                    {t("usage-policies.content.agreement.description")}{" "}
                    <a href="mailto:customerservice@zonezapp.com">customerservice@zonezapp.com</a>.
                </p>
                <p>{t("usage-policies.content.agreement.details")}</p>

            </section>

            <section className="policy-usage">
                <h2>{t("usage-policies.usagePolicy.title")}</h2>

                <h3>{t("usage-policies.usagePolicy.backgroundAccess.title")}</h3>
                <p>{t("usage-policies.usagePolicy.backgroundAccess.description")}</p>
                <ul>
                    {(t("usage-policies.usagePolicy.backgroundAccess.points", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <p>{t("usage-policies.usagePolicy.acknowledgment")}</p>
                <ul>
                    {(t("usage-policies.usagePolicy.acknowledgmentPoints", { returnObjects: true }) || []).map(
                        (point, index) => (
                            <li key={index}>{point}</li>
                        )
                    )}
                </ul>

                <h3>{t("usage-policies.usagePolicy.transparency.title")}</h3>
                <p>
                    {t("usage-policies.usagePolicy.transparency.details")}{" "}
                    <a href="mailto:customerservice@zonezapp.com">customerservice@zonezapp.com</a>.
                </p>

            </section>
        </div>

    );
};

export default UsagePolicies;