import React, { useState } from 'react';
import './TopFooter.css';
import logo from '../../logo.png';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from '../../config';

const TopFooter = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();

    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email format and length
        if (!emailRegex.test(email)) {
            setMessage(`${t('op.top-footer.wrappers.third.errorhandling.formVaildation.validateEmail.regexValidation')}`);
            return;
        }

        if (email.length > 30) {
            setMessage(`${t('op.top-footer.wrappers.third.errorhandling.formVaildation.validateEmail.lengthVaildation')}`);
            return;
        }

        try {
            // Send POST request
            const response = await fetch(`${config.FOOTER_SUBSCRIPTION_ENDPOINT}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    origin: 'Newsletter',
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setEmail('');
                setMessage(`${email}, ${t('op.top-footer.wrappers.third.errorhandling.handleSubmit.responseMessage')}`);

                // Scroll to subscription section and reload
                window.location.href = '#subscription';
                window.location.reload();
            } else {
                setMessage(`${t('op.top-footer.wrappers.third.errorhandling.handleSubmit.errorMessageOnElse')}`);
            }
        } catch (error) {
            setMessage(`${t('op.top-footer.wrappers.third.errorhandling.handleSubmit.errorMessageOnCatch')}`);
        }
    };

    return (
        <div className="layout_padding footer_section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div><img src={logo} width="70" height="75" alt="logo" /></div>
                        <p className="dolor_text">
                            {t('op.top-footer.wrappers.first')}
                        </p>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 quick-links">
                        <h1 className="quick_text"> {t('op.top-footer.wrappers.second.title')} </h1>
                        <div className="chevron_arrow">
                            <ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/terms&conditions">
                                <span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.terms-&-privacy-policy')} </span>
                            </Link>
                        </div>
                        <div className="chevron_arrow">
                            <ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/usage-policies">
                                <span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.data-usage-policies')} </span>
                            </Link>
                        </div>
                        <div className="chevron_arrow">
                            <ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/zonezapp">
                                <span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.registeration')} </span>
                            </Link>
                        </div>
                        <div className="chevron_arrow">
                            <ChevronRight className='chevron_arrow_icon' />
                            <Link className='top-footer-link' to="/faqs">
                                <span className="padding-left"> {t('op.top-footer.wrappers.second.exteneded-links.faq')} </span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3 emial-subscription">
                        <h1 className="subscribe_text"> {t('op.top-footer.wrappers.third.title')} </h1>
                        <p className="ipsum_text">
                            {t('op.top-footer.wrappers.third.sub-text')}
                        </p>

                        <form onSubmit={handleSubmit}>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="subscribe-email"
                                id="st-email"
                                placeholder={t('op.top-footer.wrappers.third.placeholder')}
                                className="email_text"
                                required
                            />
                            <input type="submit" className="submit_text" name="subscribe-submit" value={t('op.top-footer.wrappers.third.action-button')} />
                            <label htmlFor="st-email" className="st-subscribe-message">{message}</label>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopFooter;