import React, { useEffect, useRef } from 'react';
import './About.css';
import AboutIMG_One from '../../assets/mockups/about-1.png';
import AboutIMG_Two from '../../assets/backgrounds/about-2.png';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const About = () => {
    // Refs for animated elements
    const aboutSectionRef = useRef(null);
    const headingRef = useRef(null);
    const textRef = useRef(null);
    const textRefTwo = useRef(null);
    const imageContainerRef = useRef(null);
    const { t } = useTranslation();


    useEffect(() => {
        const options = {
            root: null, // The viewport will be the root
            threshold: 0.1, // 10% visibility triggers the animation
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'about') {
                    // Trigger animations for all elements inside the About section
                    if (headingRef.current) {
                        headingRef.current.classList.add('animate__animated', 'animate__fadeInDown', 'animate__delay-2s');
                    }
                    if (textRef.current) {
                        textRef.current.classList.add('animate__animated', 'animate__fadeInDown');
                    }
                    if (textRefTwo.current) {
                        textRefTwo.current.classList.add('animate__animated', 'animate__fadeInDown', 'animate__delay-2s');
                    }
                    if (imageContainerRef.current) {
                        imageContainerRef.current.classList.add('animate__animated', 'animate__fadeIn');
                    }
                }
            });
        }, options);

        // Observe the entire About section
        if (aboutSectionRef.current) {
            observer.observe(aboutSectionRef.current);
        }

        // Cleanup the observer when component unmounts
        return () => {
            if (aboutSectionRef.current) observer.unobserve(aboutSectionRef.current);
        };
    }, []);

    return (
        <section id="about" ref={aboutSectionRef} className="about-wrapper section">
            <div className="container">
                <div className='featurette-divider'></div>
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 ref={headingRef} className="featurette-heading">
                            <Trans i18nKey="op.about.header">
                                About <span className="text-highlight">ZoneZapp</span>d
                            </Trans>
                        </h2>
                        <p ref={textRef} className="text">
                            {t('op.about.paragraph-1')}
                        </p>
                        <br />
                        <p ref={textRefTwo} className="text">
                            {t('op.about.paragraph-2')}
                        </p>
                    </div>
                    <div className="col-md-5 order-md-1">
                        <div ref={imageContainerRef} className="about-image-container">
                            <img className="about-image-one" src={AboutIMG_One} alt="about-sketch" />
                            <img className="about-image-two" src={AboutIMG_Two} alt="about-sketch" />
                        </div>
                    </div>
                </div>
                <div className='featurette-divider'></div>
            </div>
        </section>
    );
};

export default About;