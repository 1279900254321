import React from 'react';
import './styles/Zonezapp.css';
import Contact from '../contact/Contact';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';



const ContactUs = ({ toggleTheme }) => {
    return(
        <section id='contactUs' className='contactus-wrapper'>
            <StickyNavbarOnePager toggleTheme={toggleTheme} />
            <Contact />
        </section>
    );
};

export default ContactUs;