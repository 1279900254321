import React, { useEffect } from "react";
import "./styles/HowItWorks.css";

import ContentImage3 from '../../assets/mockups/OTP.png';
import ContentImage5 from "../../assets/mockups/find-zone.png";
import ContentImage8 from "../../assets/mockups/manage-zone.png";
import ContentImage11 from "../../assets/mockups/be-informed.png";
import ContentImage4 from '../../assets/mockups/login-zonezapp.png';
import ContentImage9 from "../../assets/mockups/stay-connected.png";
import ContentImage2 from '../../assets/mockups/getting-started.png';
import ContentImage6 from "../../assets/mockups/customize-zone-1.png";
import ContentImage7 from "../../assets/mockups/customize-zone-2.png";
import ContentImage10 from "../../assets/mockups/subscribe-zones.png";
import ContentImage1 from "../../assets/images/zonezapp-board-view.png";

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';


function HowItWorks({ toggleTheme }) {

    const { t } = useTranslation();

    // Function to add the animation class when elements come into view
    const handleScrollAnimations = () => {
        const elements = document.querySelectorAll('.animate-on-scroll');
        const viewportHeight = window.innerHeight;

        elements.forEach((el) => {
            const position = el.getBoundingClientRect().top;
            if (position < viewportHeight - 50) {
                el.classList.add('animate__animated', el.getAttribute('data-animate'));
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollAnimations);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('scroll', handleScrollAnimations);
    }, []);

    return (
        <section id="appflow" className="appflow-wrapper section">
            <StickyNavbarOnePager toggleTheme={toggleTheme} />

            <section className="vh-100 d-flex align-items-center">
                <div className="container col-xxl-8 px-4 py-5">
                    <div className="row flex-lg-row-reverse align-items-center g-5">
                        <div className="col-10 col-sm-8 col-lg-6 mx-auto">
                            <img
                                src={ContentImage1}
                                className="d-block mx-lg-auto img-fluid mt-4 shadow rounded-2 animate-on-scroll"
                                alt="How ZoneZapp Works"
                                width="700"
                                height="500"
                                loading="lazy"
                                data-animate="animate__fadeInUp"
                            />
                        </div>
                        <div className="col-lg-6 text-center text-lg-start animate-on-scroll" data-animate="animate__fadeInDown">
                            <h2 className="mb-3 featurette-heading">
                                <Trans i18nKey="appflow.introduction.welcome">
                                    Wel<span className="text-highlight">come</span> to <span className="text-highlight">ZoneZapp</span>!
                                </Trans>
                            </h2>
                            <p className="lead text-justify">
                                {t('appflow.introduction.description1')}
                            </p>
                            <br />
                            <p className="lead text-justify">
                                {t('appflow.introduction.description2')}
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.gettingStarted.heading">
                                    Getting Started with <span className="text-highlight">ZoneZapp</span>.
                                </Trans>
                            </h2>
                            <p className="text">{t('appflow.gettingStarted.sub-heading')}</p>
                            <ol className="text">
                                <li>
                                    <Trans i18nKey="appflow.gettingStarted.steps.1">
                                        <strong>Enter your email address</strong> – This will be used to log in and receive updates.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.gettingStarted.steps.2">
                                        <strong>Set a secure password</strong> – Choose one that's easy for you to remember but hard for others to guess.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.gettingStarted.steps.3">
                                        <strong>Create your profile name</strong> – Pick a name that represents you in the ZoneZapp community.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.gettingStarted.steps.4">
                                        <strong>Add your phone number</strong> – This helps us keep your account secure and connected.
                                    </Trans>
                                </li>
                            </ol>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage2} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.signUp.heading">
                                    Complete Your <span className="text-highlight">Sign-Up</span> in a Few <span className="text-highlight">Simple</span> Steps<span className="text-highlight">.</span>
                                </Trans>
                            </h2>
                            <ol className="mt-4 text">
                                <li>
                                    <Trans i18nKey="appflow.signUp.steps.1">
                                        <strong>Click the <span style={{ color: '#ff6f00' }}>Sign up</span> button</strong> after entering your details.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.signUp.steps.2">
                                        <strong>Receive a 6-digit OTP</strong> (One-Time Password) via SMS on your phone.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.signUp.steps.3">
                                        <strong>Enter the OTP</strong> in the verification field to confirm your account.
                                    </Trans>
                                </li>
                            </ol>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage3} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.login.heading">
                                    Login and Get <span className="text-highlight">Started</span>.
                                </Trans>
                            </h2>
                            <ol className="mt-4 text">
                                <li><Trans i18nKey="appflow.login.steps.1">
                                    Open the ZoneZapp login page.
                                </Trans></li>
                                <li><Trans i18nKey="appflow.login.steps.2">
                                    Enter your <strong>email address</strong> and <strong>password</strong> you used to create your account.
                                </Trans></li>
                                <li><Trans i18nKey="appflow.login.steps.3">
                                    Click the <strong>Login</strong> button to access your account.
                                </Trans></li>
                            </ol>
                            <p className="text">{t('appflow.login.confirmation')}</p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage4} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="px-4 pt-5 my-5 text-center">
                        <h2 className="display-4 text-center featurette-heading">
                            <Trans i18nKey="appflow.findZone.heading">
                                Find Your <span className="text-highlight">Zone</span> on the <span className="text-highlight">Map.</span>
                            </Trans>
                        </h2>
                        <div className="col-lg-6 mx-auto">
                            <ol className="mt-4 text text-center">
                                <li>
                                    <Trans i18nKey="appflow.findZone.steps.1">
                                        Use the <strong>search bar</strong> to type in the location you want, or select it directly on the map.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.findZone.steps.2">
                                        Once you choose a location, a circle will appear on the map—this is your <strong>Zone</strong>.
                                    </Trans>
                                </li>
                            </ol>
                        </div>
                        <div className="overflow-hidden" style={{ maxHeight: '51vh' }}>
                            <div className="container px-5">
                                <img src={ContentImage5} className="about-image-one" alt="about-sketch" style={{ position: 'sticky' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.customizeZone.heading">
                                    Customize <span className="text-highlight">Your</span> Zone<span className="text-highlight">.</span>
                                </Trans>
                            </h2>
                            <ol className="mt-4 text">
                                <li>
                                    <Trans i18nKey="appflow.customizeZone.steps.1">
                                        The size of your Zone is determined by the radius you set in your settings.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.customizeZone.steps.2">
                                        Drag your Zone on the map to place it exactly where you want.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.customizeZone.steps.3">
                                        When you're ready, click <strong>"Zapp!"</strong> to confirm.
                                    </Trans>
                                </li>
                            </ol>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage6} alt="about-sketch" />
                                <img className="about-image-two" src={ContentImage7} alt="about-sketch" style={{ height: 'auto', right: '-160px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.manageZone.heading">
                                    Manage Your <span className="text-highlight">Zone</span> and <span className="text-highlight">Conversations</span>.
                                </Trans>
                            </h2>
                            <p className="text">{t('appflow.manageZone.sub-heading')}</p>
                            <ul className="mt-4 text">
                                <li>
                                    <Trans i18nKey="appflow.manageZone.steps.1">
                                        Creating a Zone will either form a new Zone or connect you to an existing one in the area.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.manageZone.steps.2">
                                        To manage conversations, click the <strong>"Talks"</strong> icon in your navigation bar. You can:
                                    </Trans>
                                </li>
                                <ul>
                                    <li>
                                        <Trans i18nKey="appflow.manageZone.steps.subSteps.1">
                                            Join ongoing discussions within subscribed Zones.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans i18nKey="appflow.manageZone.steps.subSteps.2">
                                            Remove yourself from any conversations as needed.
                                        </Trans>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage8} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.stayConnected.heading">
                                    Stay <span className="text-highlight">Connected</span> with <span className="text-highlight">ZoneZapp</span>.
                                </Trans>
                            </h2>
                            <p className="text">
                                {t('appflow.stayConnected.description')}
                            </p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage9} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7 order-md-2">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.manageSubscribedZones.heading">
                                    Manage Your <span className="text-highlight">Subscribed </span> Zones <span className="text-highlight">with  </span>Ease.
                                </Trans>
                            </h2>
                            <p className="text">
                                {t('appflow.manageSubscribedZones.description')}
                            </p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage10} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row featurette">
                        <div className="col-md-7">
                            <h2 className="featurette-heading">
                                <Trans i18nKey="appflow.beInformed.heading">
                                    Always Be in the <span className="text-highlight">Loop </span> and  <span className="text-highlight">Informed</span>.
                                </Trans>
                            </h2>
                            <ul className="mt-4 text">
                                <li>
                                    <Trans i18nKey="appflow.beInformed.steps.1">
                                        When you share information in a Zone, all ZoneZapp users within that Zone will receive it, making communication and updates effortless.
                                    </Trans>
                                </li>
                                <li>
                                    <Trans i18nKey="appflow.beInformed.steps.2">
                                        Want to stay updated even when you're not in a Zone?
                                    </Trans>
                                </li>
                                <ul>
                                    <li>
                                        <Trans i18nKey="appflow.beInformed.steps.subSteps.1">
                                            Subscribe to Zones you're interested in by clicking the <strong>notification icon</strong> next to the <strong>attachment icon</strong>.
                                        </Trans>
                                    </li>
                                </ul>
                            </ul>
                            <p className="text">{t('appflow.beInformed.confirmation')}</p>
                        </div>
                        <div className="col-md-5 order-md-1">
                            <div className="about-image-container">
                                <img className="about-image-one" src={ContentImage11} alt="about-sketch" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </section>

    );
}

export default HowItWorks;