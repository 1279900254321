import React from 'react';
import './styles/FAQs.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StickyNavbarOnePager from '../sticky-navbar/StickyNavbarOnePager';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container } from '@mui/material';

import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const FAQ = ({ toggleTheme }) => {
    const { t } = useTranslation();

    return (
        <section id="faqs" className="faqs-wrapper section">

            <StickyNavbarOnePager toggleTheme={toggleTheme} />

            <br />
            <br />

            <Container maxWidth="md" className="faq-container">

                {/* Introduction Section */}
                <section className="faq-intro">
                    <Typography variant="h4" gutterBottom>
                        <Trans i18nKey="faq.introduction-section.header">
                            Frequently Asked Questions<span className='o-hue'>?</span>
                        </Trans>
                    </Typography>
                    <Typography variant="body1">
                        {t('faq.introduction-section.sub-text')}
                    </Typography>
                </section>

                {/* General Questions Section */}
                <section className="faq-section">
                    <Typography variant="h5" gutterBottom>
                        {t('faq.general-questions-section.header')}
                    </Typography>

                    {/* Question 1 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.general-questions-section.questions.question-1.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.general-questions-section.questions.question-1.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 2 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.general-questions-section.questions.question-2.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.general-questions-section.questions.question-2.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 3 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.general-questions-section.questions.question-3.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.general-questions-section.questions.question-3.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 4 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.general-questions-section.questions.question-4.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.general-questions-section.questions.question-4.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 5 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.general-questions-section.questions.question-5.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.general-questions-section.questions.question-5.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                </section>

                <br />

                {/* Product/Service-Specific Question Section */}
                <section className="faq-section">
                    <Typography variant="h5" gutterBottom>
                        {t('faq.product-specific-question-section.header')}
                    </Typography>

                    {/* Question 6 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.product-specific-question-section.questions.question-1.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.product-specific-question-section.questions.question-1.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 7 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.product-specific-question-section.questions.question-2.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.product-specific-question-section.questions.question-2.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* Question 8 */}
                    <Accordion className="faq-accordion">
                        <AccordionSummary expandIcon={<ExpandMoreIcon className='expandMoreIcon' />}>
                            <Typography className='fw-bold'>{t('faq.product-specific-question-section.questions.question-3.question')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {t('faq.product-specific-question-section.questions.question-3.answer')}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>


                </section>

            </Container>
        </section>
    );
};

export default FAQ;