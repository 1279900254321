import React, { useEffect, useState } from 'react';
import './StickyNavbar.css';
import logo from '../../logo.png';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Language from '@mui/icons-material/Language';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Segment } from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ThemeToggle from '../parent-components/Theme-toggle/ThemeToggle';

import EventEmitter from 'events';
export const emitter = new EventEmitter();


function StickyNavbar({ handleOpenModal, toggleTheme, theme, activeSection, handleNavClick }) {
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null); // Manage menu state
    const [language, setLanguage] = useState('English'); // Default language
    const [isCollapsed, setIsCollapsed] = useState(false); // Navigation Control 
    const { t, i18n } = useTranslation();

    const controlNavbar = () => {
        if (window.scrollY === 0) {
            setShowNavbar(true);
        } else if (window.scrollY > lastScrollY) {
            setShowNavbar(false);
        } else if (window.scrollY < lastScrollY) {
            setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (lang) => {
        if (lang) setLanguage(lang); // Update language if an option is selected
        setAnchorEl(null);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleMenuClose(lng);
        window.location.reload();
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    return (
        <nav className={`navbar navbar-expand-lg fixed-top ${showNavbar ? 'navbar-show' : 'navbar-hide'}`}>
            <div className="container-fluid">
                <a className="navbar-brand logo-btn" href="#">
                    <img src={logo} alt="ZoneZapp Logo" height="70" width="75" />
                    <span className="brand">ZoneZapp</span>
                </a>
                <button
                    className={`navbar-toggler ${isCollapsed ? '' : 'collapsed'}`}
                    type="button"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    // data-bs-toggle="collapse"
                    // data-bs-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded={isCollapsed}
                    aria-label="Toggle navigation"
                >
                    <Segment className="toggler-icon" />
                </button>
                <div className={`collapse navbar-collapse justify-content-center ${isCollapsed ? 'show' : ''}`} id="navbarResponsive">
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeSection === 'home' ? 'active' : ''}`}
                                href="#home"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default anchor behavior
                                    handleNavClick('home'); // Trigger the handler
                                }}
                            >
                                {t('op.header.sitemaps.home')}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeSection === 'about' ? 'active' : ''}`}
                                href="#about"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavClick('about');
                                }}
                            >
                                {t('op.header.sitemaps.about')}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeSection === 'features' ? 'active' : ''}`}
                                href="#features"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavClick('features');
                                }}
                            >
                                {t('op.header.sitemaps.features')}
                            </a>
                        </li>

                        <li className="nav-item">
                            <Link
                                className={`nav-link ${activeSection === 'appflow' ? 'active' : ''}`}
                                to="/appflow"
                            >
                                {t('op.header.sitemaps.Appflow')}
                            </Link>
                        </li>
                        <li className="nav-item">
                            {/* <a
                                className={`nav-link ${activeSection === 'contact-us' ? 'active' : ''}`}
                                href="#contact-us"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavClick('contact-us');
                                }}
                            >
                                {t('op.header.sitemaps.contact')}
                            </a> */}
                            <Link
                                className={`nav-link ${activeSection === 'faqs' ? 'active' : ''}`}
                                to="/faqs"
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     handleNavClick('faqs');
                                // }}
                            >
                                FAQ
                            </Link>
                        </li>

                        <li className="nav-item">
                            <a
                                className={`nav-link ${activeSection === 'contact-us' ? 'active' : ''}`}
                                href="#contact-us"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNavClick('contact-us');
                                }}
                            >
                                {t('op.header.sitemaps.contact')}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="d-flex ms-auto align-items-center">
                    {/* Toggle for Dark Mode */}
                    <ThemeToggle onToggleTheme={toggleTheme} theme={theme} />

                    <span className="icon-button-label">Lang:</span>
                    <IconButton
                        color="inherit"
                        onClick={handleMenuClick}
                        aria-controls="language-menu"
                        aria-haspopup="true"
                        className="icon-btn"
                    >
                        <Language className="language-icon" />
                    </IconButton>
                    <Menu
                        id="language-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => handleMenuClose(null)}
                        keepMounted
                    >
                        <MenuItem onClick={() => changeLanguage('en')} >English</MenuItem>
                        <MenuItem onClick={() => changeLanguage('fr')}>French</MenuItem>
                    </Menu>

                    <Button
                        className="download-btn"
                        variant="outlined"
                        color="secondary"
                        sx={{ marginRight: '16px' }}
                        onClick={handleOpenModal}
                    >
                        {t('op.header.buttons.get-in-touch')}
                    </Button>
                </div>
            </div>
        </nav>
    );
}

export default StickyNavbar;  