import React, { useState,useEffect } from 'react';
import { Stack, FormGroup, FormControlLabel } from '@mui/material';
import MaterialUISwitch from '../../../widgets/MaterialUISwitch';

const ThemeToggle = ({ onToggleTheme,theme }) => {
  
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('theme') === 'dark'
  );

  useEffect(() => {
    localStorage.setItem('theme', isChecked ? 'dark' : 'light');
  }, [isChecked]);

  const handleToggle = () => {
    setIsChecked((prev) => !prev);
  };

  return (

    <FormGroup>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              checked={isChecked}
              onChange={handleToggle}
              onClick={onToggleTheme}
            />
          }
        />
      </Stack>
    </FormGroup>

  );
};

export default ThemeToggle;