import React from 'react';

const PlayVerify = () => {
    return (
        <section>
            google-site-verification: google0eee67645e79983a.html
        </section>
    );
}

export default PlayVerify;